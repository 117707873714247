import React from 'react';
// import '../style/skill.css';
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { faReact, faAppStore, faPython, faDigitalOcean, faAws, faGoogle, faDocker, faUbuntu, faJenkins, faGithub, faNodeJs, faHtml5, faAngular } from '@fortawesome/free-brands-svg-icons';
import FadeInSection from '../../FadeInSections'
import Chip from '@material-ui/core/Chip';
import mindef from '../../assets/img/mindef.png';
import cl from '../../assets/img/cl.png';
import accenture from '../../assets/img/accenture.png';
import botpowered from '../../assets/img/botpowered.png';
import edf from '../../assets/img/edf.png';
import gt from "../../assets/img/gt.png";

function ResumeWork() {
  const work = [
    {
      id: 5,
      icon: gt,
      company: 'Government Technology Agency, Singapore',
      role: 'Full Stack Software Engineer (Computer Vision)',
      period: ['Apr 2023 - Jun 2024'],
      periodLength: ['1y 2m'],
      car: [
        'Developed a Telegram bot with Computer Vision for a government agency, enhancing public engagement and improving industry efficiency.',
        'Built and deployed YOLOv7 Object Detection Model training pipelines (Automated) on AWS Sagemaker, significantly improving scalability and processing efficiency.',
        'Implemented automated scripts to teardown unused AWS resources, reducing costs and manual workload effectively.',
      ],
    },
    {
      id: 4,
      icon: botpowered,
      company: 'BotPowered Solutions',
      role: 'Software Engineer',
      period: ['Aug 2020 - Present'],
      periodLength: [''],
      car: [
        'Founded BotPowered Solutions, specializing in developing innovative software products for various industries, successfully launching multiple products with over 100K users collectively.',
        'Developed QueueFront, a next-generation virtual queueing system that reduces perceived wait times, increases efficiency and satisfaction, reduces manpower costs, and enforces safe distancing.',
        'Designed CarSmart, a SaaS platform that helps car buyers and sellers stay informed and make the best purchase decisions.',
        'Built Rain Korkor Ads, a web application that utilizes Telegram authentication for easy and streamlined ad management pipeline.'
      ],
    },
    {
      id: 3,
      icon: accenture,
      company: 'Accenture',
      role: 'Software Engineer (Apprenticeship)',
      // period: ['Apr 2021 - Sep 2021'],
      period: ['Apr 2021 - Sep 2021', 'Aug 2022 - Apr 2023'],
      periodLength: ['6m', '8m'],
      car: [
        'Engineered a new microservice that deals with computer vision, facial recognition, and facial feature detection, saving up to 0.5 man hours per image verification/submission.',
        'Spearheaded comprehensive load testing using JMeter and custom scripts to efficiently evaluate performance test cases, resulting in significant time savings by eliminating the need for regression testing with each deployment.',
        'Proactively executed thorough review of all libraries and software for CVE vulnerabilities to guarantee a secure deployment of features into both staging and production environments.',
        'Successfully migrated over 700 batch jobs from a legacy on-premise system to the cloud using Quartz and AWS EventBridge + Step Functions. Implemented automation of resource provisioning through CloudFormation, enabling resource provisioning for new job rules to be sped up by a factor of 4.'
      ]
    },
    {
      id: 2,
      icon: cl,
      company: 'CrimsonLogic',
      role: 'Software Test Engineer',
      period: ['Nov 2019 - Dec 2020'],
      periodLength: ['1y 2m'],
      car: [
        "Led a comprehensive automation project within the software testing team, resulting in a 200% increase in efficiency by refactoring dozens of common Selenium C# scripts for specific scenarios and streamlining the team's rollout process.",
        "Collaborated with government agency officers to meet deadlines and clear User Acceptance Testing for over 5 major service requests, resulting in successful implementation of new services.",
      ]
    },
    {
      id: 1,
      icon: mindef,
      company: 'Ministry of Defence, Singapore',
      role: 'Research Analyst, Strategic Futures (National Service)',
      period: ['Nov 2017 - Aug 2019'],
      periodLength: ['1y 10m'],
      car: [
        'Analyzed and contributed to over 200 online news articles on new technology and ideas, with at least 20% of those being selected for internal futures defence publications, providing valuable insights and ideas for future defence strategies.',
        'Developed scripts to automate scraping of web endpoints of paid publications and document libraries using BeautifulSoup, resulting in a saving of up to 5 additional man days per subject matter, streamlining the data collection process.'
      ]
    },
    {
      id: 0,
      icon: edf,
      company: 'EDF Lab Singapore',
      role: 'Research Engineer (Internship)',
      period: ['Mar 2016 - May 2016'],
      periodLength: ['3m'],
      car: [
        "Automated and streamlined process of fetching map documents from EDF Lab's backend, significantly enhancing the efficiency of the pipeline from the backend of EDF Lab by a minimum of fivefold.",
        "Led a geospatial heat-map visualization project, utilizing ArcGIS and Javascript Leaflets with 2 datasets. Designed the project to be modular, making it compatible and scalable to other datasets, providing a powerful tool for data visualization."
      ]
    }
  ]
  return (
    <div className="container" id="edu" style={{ paddingTop: '0.5em' }}>
      <div className="skills-container" style={{ maxWidth: "80% ", paddingBottom: '0.5em' }}>
        <h2>Work Experience</h2>
        {work.map(w => (
          <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "left",
            marginBottom: '1.5em',
          }}>
            <div>
              <img src={w.icon} style={{ width: '50px', height: '50px', borderRadius: '25px' }} />
            </div>
            <div style={{
              marginLeft: "1em",
              marginTop: "0.2em"
            }}>
              <h5 style={{ marginTop: '0', marginBottom: '0', color: '#000' }}>{w.company}</h5>
              <h5 style={{ marginTop: '0', marginBottom: '0', color: '#5A5E65' }}>{w.role}</h5>
              {
                w.period.map((p, i) => (
                  <>
                    {
                      w.periodLength != "" ?
                        <h6 style={{ marginTop: '0', marginBottom: '0', color: '#5A5E65' }}>{p} ({w.periodLength[i]})</h6>
                        :
                        <h6 style={{ marginTop: '0', marginBottom: '0', color: '#5A5E65' }}>{p}</h6>
                    }
                  </>
                ))
              }
              <ol style={{ margin: '0', padding: '0', marginTop: '0.5em' }}>
                {w.car.map(c => (
                  <li style={{
                    fontSize: "70%",
                    margin: '0', padding: '0', marginTop: '0.35em'
                  }}>{c}</li>
                ))}
              </ol>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ResumeWork;