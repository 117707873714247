import React, { useState } from 'react'
import Project from '../Project'
import ResumeSkill from './ResumeSkill'

import { PDFExport } from '@progress/kendo-react-pdf';
import ResumeHeader from './ResumeHeader';
import ResumeEdu from './ResumeEdu';
import ResumeWork from './ResumeWork';

function ResumeExport() {
  return (
    <>
    {/* <div style={{
      display: 'flex',
      height: '1em',
      marginBottom: '1em',
      backgroundColor: "#000"
    }}>
      </div>
    <div style={{
      display: 'flex',
      height: '1em',
      marginBottom: '1em',
      backgroundColor: "#000"
    }}>
    </div> */}

    <div>
      <ResumeHeader/>
      <ResumeEdu/>
      <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
        <hr />
      </div>
      <ResumeWork/>
      <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
        <hr />
      </div>
      <ResumeSkill/>
      {/* <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
        <hr />
      </div> */}
      <div className="page-break"/>
      <Project hideHeader={true} />
    </div>
    </>
  )
}

export default ResumeExport