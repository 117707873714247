import React from 'react'
import profile from '../../assets/img/profile.png';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ResumeHeader() {
  return (
    <div style={{
      paddingTop: '1em',
      paddingLeft: '10%',
      paddingRight: '10%',
    }}>
    <div style={{
      display:'flex',
      flexDirection:'column',
      marginBottom: '1em',
      marginLeft: '0.5em'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <img src={profile} style={{ width: '60px', height: '60px', borderRadius: '30px'}}/>
        <div style={{
          display:'flex',
          flexDirection:'column',
          marginLeft: '1em',
          marginTop: '0.5em'
        }}>
          <h2 style={{ margin: 0, marginBottom: '0.2em', textAlign: 'left' }}>Kenneth Ng</h2>
          <h6 style={{ margin: 0, textAlign: 'left' }}><a href="tel:+6581284447">+65 81284447</a>&nbsp;|&nbsp;
          <a href="http://t.me/kenif"><FontAwesomeIcon icon={faTelegramPlane} size="sm" />&nbsp;@kenif</a>
          &nbsp;|&nbsp;
          <FontAwesomeIcon icon={faEnvelope} size="sm" />&nbsp;<a href="mailto:hi@kenif.xyz">hi@kenif.xyz</a>
          &nbsp;|&nbsp;
          <a href="https://github.com/kenifxyz">github</a>
          &nbsp;|&nbsp;
          <a href="https://in.kenif.xyz/">linkedin</a>
          &nbsp;|&nbsp;
          <a href="https://kenif.xyz/">portfolio</a>
          &nbsp;|&nbsp;
          <a href="https://blog.kenif.xyz/">blog</a>
          </h6>
        </div>
      </div>
    </div>
    <hr/>
    </div>
  )
}

export default ResumeHeader