import React, { useState, useEffect } from 'react';

function App() {
  // const [imgsLoaded, setImgsLoaded] = useState(false)
  // useEffect(() => {
  //   const loadImage = image => {
  //     return new Promise((resolve, reject) => {
  //       const loadImg = new Image()
  //       loadImg.src = image.url
  //       // wait 2 seconds to simulate loading time
  //       loadImg.onload = () =>
  //         setTimeout(() => {
  //           resolve(image.url)
  //         }, 2000)

  //       loadImg.onerror = err => reject(err)
  //     })
  //   }

  //   Promise.all(ASSETS.map(image => loadImage(image)))
  //     .then(() => setImgsLoaded(true))
  //     .catch(err => console.log("Failed to load images", err))
  // }, [])

  return  (
    <div>
      <div className="introduction" id="intro">
        <h1>Kenneth Ng</h1>
        <div className="nav-bar">
          <nav>
              <ul>
                <li><a href="#about">About</a></li>
                <li><a href="#contact">Contact</a></li>
                <li><a href="#skills">Specialties</a></li>
                <li><a href="#project">Projects</a></li>
              </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default App;