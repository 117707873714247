import React, { useState, useEffect, useRef } from 'react';
import '../style/contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faGithub, faLinkedinIn, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import FadeInSection from '../FadeInSections'
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import moment from 'moment';

function Contact() {
  const is_loading  = useRef(true);
  const [status_accent, setStatusAccent] = useState('#229ED9');
  const [is_online, setIsOnline] = useState(false);
  const [last_seen, setLastSeen] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  // const [lastSeenVisible, setLastSeenVisible] = useState(false);
  const lastSeenVisible = useRef(false);
  const prevLastSeenVisible = useRef(false);
  const lastSeenRef = useRef();

  const fetchLastSeen = () => {
    // console.log("is_loading: " + is_loading.current)
    // console.log("last seen visible: " + lastSeenVisible.current)
    if (is_loading.current == true || lastSeenVisible.current == true) {
      // broken, to fix - 13 sep 22
      // axios.get('https://api.botpowered.sg/kenif/last-seen').then(res => {
      //   if (res.data.is_online) {
      //     setStatusAccent('#22c55e');
      //     setIsOnline(true)
      //     // setIsLoading(false)
      //     is_loading.current = false
      //   } else {
      //     setStatusAccent('#229ED9');
      //     setIsOnline(false)
      //     setLastSeen("")
      //     setLastSeen(res.data.last_seen)
      //     if (res.data.last_seen != 0) {
      //       // setIsLoading(false)
      //       is_loading.current = false
      //     }
      //   }
      //   setFetchCount(fetchCount + 1)
      // })
    } else {
      // console.log("is_loading: " + is_loading.current)
      // console.log("last seen visible: " + lastSeenVisible.current)
    }
  }

  const scrollHandler = _ => {
    // to fetch last seen when element is in view, then every 3.2 seconds for the rest of the time the element is in view
    const { innerWidth: width, innerHeight: height } = window;
    const rect = lastSeenRef.current.getBoundingClientRect()
    const currentLastSeen = rect.top > 0 && rect.bottom < height
    lastSeenVisible.current = currentLastSeen
    if (prevLastSeenVisible.current == false && currentLastSeen == true) {
      fetchLastSeen()
    }
    prevLastSeenVisible.current = currentLastSeen
  };

  useEffect(() => {
    // fetch last seen every 3.2 seconds
    const interval = setInterval(() => {
      fetchLastSeen();
    }, 3200);

    window.addEventListener("scroll", scrollHandler, true);
    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);
  return  (
    <div className="getintouch contact" id="contact">
      <div className="section">

      <FadeInSection>
        <h1>Contact</h1>
        <h2>Let's build something impactful together.</h2>
        <div className="line"/>
        <p style={{ 
              paddingBottom: '0em',
              marginBottom: '0.5em',
          }}>I am constantly on the lookout for new opportunities to make a positive impact on real-world problems and drive meaningful change.</p>
        <p style={{ 
              paddingTop: '0em',
              marginTop: '0.5em',
              marginBottom: '1.5em',
          }}>If you are looking for a dedicated and experienced professional to help bring your vision to fruition, please don't hesitate to contact me.</p>
        <a href="tg://resolve?domain=kenif" style={{
                cursor: "pointer" }}>
        <Chip key={0} ref={lastSeenRef} label={
          (
            <>
              <FontAwesomeIcon 
              icon={faTelegramPlane} 
              size="lg" 
              // color={tag[1]}
              style={{ 
                marginRight: "0.3em",
                }}
              />
              <b>
                Telegram Me
              </b>
            </>
          )} 
          color={
            "default"
          }
          // variant="outlined"
          style={{ 
            fontSize: "1.2em",
            paddingTop: '1em',
            paddingBottom: '1em',
            marginRight: "6px", 
            marginBottom:"0",
            color: '#fff',
            cursor: "pointer",
            backgroundColor: status_accent,
          }}/>
          <div
          style={
            {
              paddingTop: '0',
              marginLeft: '0.5em'
            }
          }
          >
            <h4
            key={fetchCount}
            style={{
              marginTop: '0.5em',
              color: status_accent,
            }}
            >
              {
                is_loading.current || last_seen == 0 ? '' :
                is_online ? "I'm online right now!" 
                :
                "I was online just " + moment.unix(last_seen).fromNow() + "."
              }
            </h4>
          </div>
        </a>
        
        <div className="info" style={{
          marginTop: "2.5em"
        }}>
          <ul>
            <li><a href="https://linkedin.com/in/kenifxyz" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} size="2x"/></a></li>
            <li><a href="https://github.com/kenifxyz" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} size="2x"/></a></li>
            <li><a href="https://instagram.com/kenneth.ntz" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} size="2x"/></a></li>
          </ul>
        </div>

        </FadeInSection>
      </div>

    </div>
  );
}

export default Contact;