import React, { useState } from 'react';
import '../style/App.css'
import '../style/project.css';
import FadeInSection from '../FadeInSections';
import Chip from '@material-ui/core/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {projectData} from '../data/projectData';


// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
function Project(props) {
  const hideHeader = props.hideHeader;
  const data = projectData
  // console.log("items in projectData: " + data.length)
  // create array of states based on size of data array
  const [assetsLoaded, SetAssetsLoaded] = useState(false);
  const [assetCount, SetAssetCount] = useState(0);

  const loadAsset = () => {
    // if (assetCount < (data.length * 2)-1) {
    //   SetAssetCount(assetCount + 1);
    //   console.log(assetCount);
    // } else {
    //   SetAssetsLoaded(true);
    // }
  }

  const emptyResult = {
    id: 0,
    href: "",
    icon: "",
    thumbnail: "",
    title: "No Results Found",
    description: "There were no projects that matched all the tags you have chosen.",
    tags: []
  }

  // sort data by length of tags
  const sortedData = data.sort((a, b) => {
    if (a.isSunset !== b.isSunset) {
      return a.isSunset ? 1 : -1;
    }
    return b.tags.length - a.tags.length;
  });

  // get all unique tags
  const uniqueTags = sortedData.reduce((acc, curr) => {
    curr.tags.forEach(tag => {
      // if acc name is not in an object in the array, add it
      if (!acc.some(obj => obj.name === tag.name)) {
        acc.push({'name' : tag.name, 'type' : tag.type});
      }
    });
    return acc;
  }, []);
    
  const options = uniqueTags.map((option) => {
    return {
      group: option.type,
      ...option
    };
  });

  // copy all data to rendered data state
  const [renderedData, setRenderedData] = useState(sortedData);
  const [selectedTags, setSelectedTags] = useState([]);

  
  return  (
    <div className="projects-container" id="project">
    <FadeInSection>
      { hideHeader &&
      <h2 style={hideHeader? { marginTop: "2em" }: null}>Featured Personal Projects</h2>
      }
      { !hideHeader &&
      <>
      <h1>Featured Projects</h1>
      <div className="projects-filter">
        <Autocomplete
          multiple
          freeSolo={false}
          id="checkboxes-tags-demo"
          options={options.sort((a, b) => b.group > a.group)}
          groupBy={(option) => option.group}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            // do not render option if it is already selected
            <div>
              {selectedTags.includes(option.name) ? null : (
                <li {...props}>
                  {option.name}
                </li>
              )}
            </div>
          )}
          style={{ width: "100%" }}

          onChange={(event, value) => {
            // change renderData to only include projects with selected tags, but show all projects if no tags are selected
            var nameList = []
            for (let v of value) {
              nameList.push(v.name);
            }
            if (nameList.length === 0) {
              setRenderedData(sortedData);
            } else {
              var filteredData = sortedData.filter(project => {
                var projectTags = project.tags.map(tag => tag.name);
                return nameList.every(name => projectTags.includes(name));
              });
              // if no projects match the selected tags, show empty result
              if (filteredData.length === 0) {
                setRenderedData([emptyResult]);
              } else {
                setRenderedData(filteredData);
              }
            }
            setSelectedTags(nameList);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Filter Featured Projects by Tags" placeholder="Leave Empty to Show All" />
          )}
        />
      </div>
      </>
      }
      <div className="projects-grid">
        {
          renderedData.map((item, value) => (
              <div className="project" key={value}>
                { item.href === "" || item.isDisabled ? 
                    item.thumbnail === "" ? null : <img loading="lazy" src={item.thumbnail} className={item.isDisabled ? "round" : "zoom round"}
                    style={{
                      ...(hideHeader ? { "borderRadius" : '1em' } : null),
                      ...(item.isSunset ? { filter: 'grayscale(100%)' } : null)
                    }}
                    onLoad={() => loadAsset()}
                    alt={ item.title + " banner image"}
                    width="100%"/> : (
                <a href={item.href} target="_blank" rel="noreferrer"><img loading="lazy" src={item.thumbnail} className={item.isDisabled ? "round" : "zoom round"}
                style={{
                  ...(hideHeader ? { "borderRadius" : '1em' } : null),
                  ...(item.isSunset ? { filter: 'grayscale(100%)' } : null)
                }}
                alt={item.title + " banner image"}
                onLoad={() => loadAsset()}
                width="100%"/></a>
                )}
                <div style={{
                  // backgroundColor: '#f5f5f5',
                  // paddingTop: "5em",
                  // paddingBottom: "0.5em",
                  // marginTop: "-5em",
                  // borderRadius: "0 0 2em 2em",
                  paddingLeft: "1em",
                  paddingRight: "1em"
                }}>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                  {
                    item.icon !== "" ? (
                      <img src={item.icon} 
                      alt={(item.title + " logo")}
                      loading="lazy"
                      onLoad={() => loadAsset()}
                      style={{
                        marginTop: "1.22em",
                        marginRight: "1em",
                        height: !hideHeader ? "50px" : "35px",
                        width: !hideHeader ? "50px" : "35px",
                        borderRadius: "50%",
                        ...(item.isSunset ? { filter: 'grayscale(100%)' } : null)
                      }}/>
                    ) : null
                  }
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                  }}>
                    { item.href === "" || item.isDisabled ?
                        !hideHeader ? 
                        <h2 className="static" style={{ marginBottom: '0.4em'}}>{item.title}</h2>
                        :
                        <h4 className="static" style={{ marginBottom: '0.4em'}}>{item.title}</h4>
                      : 
                      
                      !hideHeader ? (
                        <a href={item.href} target="_blank" rel="noreferrer" style={{
                          display: 'flex'
                        }}>
                          <h2 style={{ marginBottom: '0.4em' }}>{item.title}</h2>
                          {
                          item.isNew &&
                          <Chip label="New" style={{ backgroundColor: '#f5f5f5', color: '#000', marginLeft: '0.8em', marginTop: '1.2em' }}/>
                          }
                        </a>
                      ) 
                      :(
                        <a href={item.href} target="_blank" rel="noreferrer" style={{
                          display: 'flex'
                        }}>
                          <h4 style={{ marginBottom: '0.4em', color:'#000' }}>{item.title}</h4>
                          {
                          item.isNew &&
                          <Chip label="New" style={{ backgroundColor: '#f5f5f5', color: '#000', marginLeft: '0.8em', marginTop: '1.2em' }}/>
                          }
                        </a>
                      )
                    }
                    { !hideHeader ?
                    <h5 style={{ marginTop: '0', marginBottom: '0', color: '#9ca3af' }}>{item.period}</h5>
                    :
                    <h6 style={{ marginTop: '0', marginBottom: '0', color: '#9ca3af' }}>{item.period}</h6>
                    }
                  </div>
                </div>
                { !hideHeader ?
                  <p>{item.description}</p>
                  :
                  <p style={{ fontSize:'70%' }}>{item.description}</p>
                }
                  { item.tags.length === 0 ? null : (
                  <div style={{ fontSize: !hideHeader ? '100%' : '50%', }}>Tags:&nbsp; 
                    {
                      item.tags.map((tag, index) => (
                        <Chip 
                          key={index}
                          label={tag.name}
                          size={!hideHeader ? 'medium' : 'small'}
                          style={{
                            backgroundColor: selectedTags.includes(tag.name) ? '#cffafe' : '#E0E0E0',
                            fontFamily: 'monospace',
                            fontSize: '100%',
                            marginRight: '0.3em',
                            marginBottom: '0.6em'
                          }}></Chip>
                      ))
                    }
                  </div>)
                  }
                </div>
              </div>
          ))
        }
      </div>
    </FadeInSection>
    </div>
  );
}

export default Project;