import React from 'react';

import icon100 from "../assets/img/logos/100.png";
import banner100 from "../assets/img/banners/100.png";
import icon105 from "../assets/img/logos/105.png";
import banner105 from "../assets/img/banners/105.png";
import icon150 from "../assets/img/logos/150.png";
import banner150 from "../assets/img/banners/150.png";
import icon175 from "../assets/img/logos/175.png";
import banner175 from "../assets/img/banners/175.png";
import icon200 from "../assets/img/logos/200.png";
import banner200 from "../assets/img/banners/200.png";
import icon300 from "../assets/img/logos/300.png";
import banner300 from "../assets/img/banners/300.png";
import icon400 from "../assets/img/logos/400.png";
import banner400 from "../assets/img/banners/400.png";
import icon500 from "../assets/img/logos/500.png";
import banner500 from "../assets/img/banners/500.png";
import icon600 from "../assets/img/logos/600.png";
import banner600 from "../assets/img/banners/600.png";
import icon700 from "../assets/img/logos/700.png";
import banner700 from "../assets/img/banners/700.png";
import icon800 from "../assets/img/logos/800.png";
import banner800 from "../assets/img/banners/800.png";
import icon900 from "../assets/img/logos/900.png";
import banner900 from "../assets/img/banners/900.png";
import icon1000 from "../assets/img/logos/1000.png";
import banner1000 from "../assets/img/banners/1000.png";
import icon1100 from "../assets/img/logos/1100.png";
import banner1100 from "../assets/img/banners/1100.png";
import icon1200 from "../assets/img/logos/1200.png";
import banner1200 from "../assets/img/banners/1200.png";
import icon1300 from "../assets/img/logos/1300.png";
import banner1300 from "../assets/img/banners/1300.png";
import icon1400 from "../assets/img/logos/1400.png";
import banner1400 from "../assets/img/banners/1400.png";
import icon1500 from "../assets/img/logos/1500.png";
import banner1500 from "../assets/img/banners/1500.png";
import icon1600 from "../assets/img/logos/1600.png";
import banner1600 from "../assets/img/banners/1600.png";

  export const projectData = [{
    "id": 100,
    "href": "https://rainkorkor.com/",
    "icon": icon100,
    "thumbnail": banner100,
    "title": "Rain Korkor",
    "period": "Dec 2019 - Present",
    "periodIndex": 201912,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Rain Korkor is a Telegram bot that helps you quickly and easily view current weather imagery over Singapore.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "Heroku", "type": "Servers & Infrastructure" },
      { "name": "Flask", "type": "Web Frameworks" },
      { "name": "Firebase Realtime", "type": "Database" },
      { "name": "Selenium", "type": "Libraries" },
      { "name": "OpenCV", "type": "Libraries" },
      { "name": "MoviePy", "type": "Libraries" },
      { "name": "Pillow", "type": "Libraries" }
    ]
  },
  {
    "id": 105,
    "href": "https://ads.rainkorkor.com/",
    "icon": icon105,
    "thumbnail": banner105,
    "title": "Rain Korkor Ads",
    "period": "Aug 2021 - Present",
    "periodIndex": 202108,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Rain Korkor Ads is a web app that uses Telegram authentication. It gives advertisers an easy way to create and manage their ads on the Rain Korkor platform.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "Node.js", "type": "Languages"},
      { "name": "Google Cloud Functions", "type": "Servers & Infrastructure" },
      { "name": "Heroku", "type": "Servers & Infrastructure" },
      { "name": "React", "type": "Web Frameworks" },
      { "name": "Flask", "type": "Web Frameworks" },
      { "name": "Express", "type": "Web Frameworks" },
      { "name": "TailwindCSS", "type": "Web Frameworks" },
      { "name": "MongoDB", "type": "Database" }
    ]
  },
  {
    "id": 150,
    "href": "https://queuefront.com/",
    "icon": icon150,
    "thumbnail": banner150,
    "title": "QueueFront",
    "period": "Feb 2021 - Nov 2023",
    "periodIndex": 202102,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : true,
    "description": "QueueFront was a B2B SaaS platform that helped businesses manage physical queues at their premises.",
    "tags": [
      { "name": "Node.js", "type": "Languages"},
      { "name": "AWS EC2", "type": "Servers & Infrastructure" },
      { "name": "Nginx", "type": "Servers & Infrastructure" },
      { "name": "React", "type": "Web Frameworks" },
      { "name": "Express", "type": "Web Frameworks" },
      { "name": "MongoDB", "type": "Database" },
      { "name": "Socket.IO", "type": "Libraries" },
      { "name": "Passport.js", "type": "Libraries" }
    ]
  },
  {
    "id": 175,
    "href": "https://t.me/carsmart_bot",
    "icon": icon175,
    "thumbnail": banner175,
    "title": "CarSmart",
    "period": "Jan 2022 - Oct 2023",
    "periodIndex": 202201,
    "isNew" : false,
    "isDisabled" : true,
    "isSunset" : true,
    "description": "CarSmart was a SaaS platform that scraped SgCarMart for newly listed used cars, and notified users based on filters they had pre-set through a Telegram bot.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "Node.js", "type": "Languages" },
      { "name": "DigitalOcean", "type": "Servers & Infrastructure" },
      { "name": "RabbitMQ", "type": "Servers & Infrastructure" },
      { "name": "React", "type": "Web Frameworks" },
      { "name": "Flask", "type": "Web Frameworks" },
      { "name": "MongoDB", "type": "Database" },
      { "name": "Stripe Checkout", "type": "Libraries" },
      { "name": "BeautifulSoup", "type": "Libraries" }
    ]
  },
  {
    "id": 200,
    "href": "https://t.me/samanauntybot",
    "icon": icon200,
    "thumbnail": banner200,
    "title": "Saman Aunty",
    "period": "Dec 2020 - Present",
    "periodIndex": 202012,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Saman Aunty is a Telegram bot that assists with looking up traffic summons.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "AWS Lambda", "type": "Servers & Infrastructure" },
      { "name": "AWS EventBridge", "type": "Servers & Infrastructure" },
      { "name": "Firebase Realtime", "type": "Database" },
      { "name": "BeautifulSoup", "type": "Libraries" }
    ]
  },
  {
    "id": 300,
    "href": "https://t.me/bittiebusbot",
    "icon": icon300,
    "thumbnail": banner300,
    "title": "Bittie",
    "period": "Jan 2021 - Present",
    "periodIndex": 202101,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Bittie is a Telegram bot that allows users to check bus arrival timings at the bus stop they're currently at, and be notified when their bus is arriving.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "AWS Lambda", "type": "Servers & Infrastructure" },
      { "name": "AWS EventBridge", "type": "Servers & Infrastructure" },
      { "name": "Firebase Realtime", "type": "Database" }
    ]
  },
  {
    "id": 400,
    "href": "https://t.me/slurpeebot",
    "icon": icon400,
    "thumbnail": banner400,
    "title": "The Slurpee Network",
    "period": "Sep 2020 - Present",
    "periodIndex": 202009,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "The Slurpee Network is a Telegram bot that helps connect you with the nearest 7-Eleven that are equipped slurpee machines just by sending your location.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "AWS Lambda", "type": "Servers & Infrastructure" },
      { "name": "Firebase Realtime", "type": "Database" }
    ]
  },
  {
    "id": 500,
    "href": "https://k92.kenif.xyz/",
    "icon": icon500,
    "thumbnail": banner500,
    "title": "Kiss92 Explorer",
    "period": "Dec 2021 - Present",
    "periodIndex": 202112,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Kiss92 Explorer is a web app that allows you to view the top played songs on Singapore's Kiss92 radio station. Paired with a Telegram bot watcher, you can also be notified when a new song is played for the first time.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "Node.js", "type": "Languages" },
      { "name": "DigitalOcean", "type": "Servers & Infrastructure" },
      { "name": "Netlify", "type": "Servers & Infrastructure" },
      { "name": "React", "type": "Web Frameworks" },
      { "name": "Flask", "type": "Web Frameworks" },
      { "name": "TailwindCSS", "type": "Web Frameworks" },
      { "name": "MongoDB", "type": "Database" }
    ]
  },
  {
    "id": 600,
    "href": "https://af.kenif.xyz/",
    "icon": icon600,
    "thumbnail": banner600,
    "title": "AF Stats",
    "period": "Oct 2021 - Apr 2022",
    "periodIndex": 202110,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : true,
    "description": "AF Stats was a web app that displays gym crowd capacities at two Anytime Fitness gym outlets in Choa Chu Kang, Singapore. The project was sunset in April 2022 as crowd monitoring was no longer needed due to the government's easing of COVID measures.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "Node.js", "type": "Languages" },
      { "name": "Netlify", "type": "Servers & Infrastructure" },
      { "name": "AWS Lambda", "type": "Servers & Infrastructure" },
      { "name": "React", "type": "Web Frameworks" },
      { "name": "TailwindCSS", "type": "Web Frameworks" },
      { "name": "MongoDB", "type": "Database" },
      { "name": "Puppeteer", "type": "Libraries" },
      { "name": "Tesseract OCR", "type": "Libraries" }
    ]
  },
  {
    "id": 700,
    "href": "",
    "icon": icon700,
    "thumbnail": banner700,
    "title": "Got Covid?",
    "period": "May 2021 - Oct 2021",
    "periodIndex": 202105,
    "isNew" : false,
    "isDisabled" : true,
    "isSunset" : true,
    "description": "Got Covid? was a Telegram channel powered by a bot backend. It notified users daily of public places visited by COVID cases while infectious as the data became available. The project was sunset in October 2021 due to the Singapore Government's shift away from daily case details, in line with their 'living with covid' strategy.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "Node.js", "type": "Languages" },
      { "name": "Flask", "type": "Servers & Infrastructure" },
      { "name": "Puppeteer", "type": "Libraries" },
      { "name": "BeautifulSoup", "type": "Libraries" }
    ]
  },
  {
    "id": 800,
    "href": "https://4d.kenif.xyz",
    "icon": icon800,
    "thumbnail": banner800,
    "title": "Win How Much?",
    "period": "Jan 2021 - Present",
    "periodIndex": 202101,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Win How Much? is a simple web app that helps easily check how much one could potentially win from Singapore's 4D lottery given big and small bets.",
    "tags": [
      { "name": "Node.js", "type": "Languages" },
      { "name": "Netlify", "type": "Servers & Infrastructure" },
      { "name": "React", "type": "Web Frameworks" }
    ]
  },
  {
    "id": 900,
    "href": "https://t.me/thestickerifybot",
    "icon": icon900,
    "thumbnail": banner900,
    "title": "Stickerify",
    "period": "Mar 2021 - Present",
    "periodIndex": 202103,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Stickerify is a Telegram bot that automates the sticker creation process in Telegram by allowing users to convert invalid images into ones with the appropriate dimensions and file format for stickers.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "Heroku", "type": "Servers & Infrastructure" },
      { "name": "OpenCV", "type": "Libraries" },
      { "name": "Pillow", "type": "Libraries" }
    ]
  },
  {
    "id": 1000,
    "href": "https://t.me/checkpointjambot",
    "icon": icon1000,
    "thumbnail": banner1000,
    "title": "Checkpoint Jam",
    "period": "Nov 2021 - Present",
    "periodIndex": 202111,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Checkpoint Jam is a Telegram bot that allows users to view the traffic situation for both land crossings between Singapore and Johor Bahru. The bot was soft launched in November 2021 when the Vaccinated Travel Lanes first opened, then subsequently fully launched on 24 March 2022 when resumption of private vehicle travel was announced.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "Node.js", "type": "Languages" },
      { "name": "Flask", "type": "Servers & Infrastructure" },
      { "name": "OpenCV", "type": "Libraries" },
      { "name": "Pillow", "type": "Libraries" },
      { "name": "Numpy", "type": "Libraries" },
      { "name": "Puppeteer", "type": "Libraries" },
      { "name": "Jimp", "type": "Libraries" },
      { "name": "Sharp", "type": "Libraries" }
    ]
  },
  {
    "id": 1100,
    "href": "https://t.me/songanywherebot",
    "icon": icon1100,
    "thumbnail": banner1100,
    "title": "Song Anywhere",
    "period": "Feb 2022 - Present",
    "periodIndex": 202202,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Song Anywhere is a Telegram bot that aims to make cross-platform song sharing easy. Send the bot a song name or link, and it will return links of the song on multiple streaming platforms - namely Apple Music, Spotify, YouTube, and YouTube Music.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "DigitalOcean", "type": "Servers & Infrastructure" },
      { "name": "Flask", "type": "Servers & Infrastructure" },
      { "name": "MongoDB", "type": "Database" },
    ]
  },
  {
    "id": 1200,
    "href": "https://www.facebook.com/BigLoveCPSC/posts/1363942567360731",
    "icon": icon1200,
    "thumbnail": banner1200,
    "title": "Give A Voice 2022 Scavenger Hunt",
    "period": "March 2022",
    "periodIndex": 202203,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Give A Voice 2022 Scavenger Hunt was a physical scavenger hunt, interfaced via a Telegram bot, hosted to raise awareness for child protection issues in Singapore. Organised by Big Love Child Protection Specialist Centre, it was held over an 8 day period & saw over 100 teams participate to win prizes worth up to SGD$2000.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "DigitalOcean", "type": "Servers & Infrastructure" },
      { "name": "Flask", "type": "Servers & Infrastructure" },,
      { "name": "MongoDB", "type": "Database" }
    ]
  },
  {
    "id": 1300,
    "href": "https://tictactoe.kenif.xyz",
    "icon": icon1300,
    "thumbnail": banner1300,
    "title": "Socket Tic Tac Toe",
    "period": "July 2022 - Present",
    "periodIndex": 202207,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Socket Tic Tac Toe is a web-based game built with React, and purely WebSocket networking. It features real-time updates in the process of joining a game and playing between two peers.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "DigitalOcean", "type": "Servers & Infrastructure" },
      { "name": "Netlify", "type": "Servers & Infrastructure" },
      { "name": "React", "type": "Web Frameworks" },
      { "name": "Socket.IO", "type": "Libraries" },
    ]
  },
  {
    "id": 1400,
    "href": "https://nf.kenif.xyz",
    "icon": icon1400,
    "thumbnail": banner1400,
    "title": "NodeFlair Explorer",
    "period": "Sep 2022 - Oct 2023",
    "periodIndex": 202209,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : true,
    "description": "NodeFlair Explorer was a web-based tool that allowed users to explore job listings found on NodeFlair and filter with much more granularity. It's been sunset as NodeFlair has since added many of the features that were previously missing, and also added CloudFlare which prevents scraping.",
    "tags": [
      { "name": "Node.js", "type": "Languages"},
      { "name": "Python", "type": "Languages" },
      { "name": "DigitalOcean", "type": "Servers & Infrastructure" },
      { "name": "Netlify", "type": "Servers & Infrastructure" },
      { "name": "React", "type": "Web Frameworks" },
      { "name": "Nginx", "type": "Servers & Infrastructure" },
      { "name": "MongoDB", "type": "Database" },
      { "name": "BeautifulSoup", "type": "Libraries" },
    ]
  },
  {
    "id": 1500,
    "href": "https://boxofficeowl.kenif.xyz",
    "icon": icon1500,
    "thumbnail": banner1500,
    "title": "Box Office Owl",
    "period": "October 2022 - Present",
    "periodIndex": 202210,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "Box Office Owl is a web app that allows users to view movie information and upcoming showtimes of movies playing at The Projector in Singapore. The Python-based backend periodically scrapes The Projector's website for latest showtimes and collates movie metadata from external sources.",
    "tags": [
      { "name": "Node.js", "type": "Languages"},
      { "name": "Python", "type": "Languages" },
      { "name": "DigitalOcean", "type": "Servers & Infrastructure" },
      { "name": "Netlify", "type": "Servers & Infrastructure" },
      { "name": "React", "type": "Web Frameworks" },
      { "name": "Nginx", "type": "Servers & Infrastructure" },
      { "name": "BeautifulSoup", "type": "Libraries" },,
      { "name": "MongoDB", "type": "Database" }
    ]
  },
  {
    "id": 1600,
    "href": "https://messagebackbot.t.me/",
    "icon": icon1600,
    "thumbnail": banner1600,
    "title": "MessageBack",
    "period": "October 2022 - Present",
    "periodIndex": 202210,
    "isNew" : false,
    "isDisabled" : false,
    "isSunset" : false,
    "description": "MessageBack is a Telegram bot that allows you to quickly set reminders, and snooze or mark them as completed when they are due.",
    "tags": [
      { "name": "Python", "type": "Languages" },
      { "name": "DigitalOcean", "type": "Servers & Infrastructure" },
      { "name": "Netlify", "type": "Servers & Infrastructure" },
      { "name": "Nginx", "type": "Servers & Infrastructure" },
      { "name": "BeautifulSoup", "type": "Libraries" },
      { "name": "MongoDB", "type": "Database" },
      { "name": "Redis", "type": "Caching" },
    ]
  }
]