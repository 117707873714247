import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import App from './components/App';
import About from './components/About';
import Skill from './components/Skill';
import Project from './components/Project';
import Contact from './components/Contact';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from './reportWebVitals';
const resumeMode = false;
Sentry.init({
  dsn: "https://551f4478f64b4e5fb08f41ecfa48cd34@o1420067.ingest.sentry.io/6765131",
  integrations: [new BrowserTracing()],
  release: "kenifxyz-portfolio@1.0.0",
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <React.StrictMode>
    {!resumeMode &&
    <App />
    }
    <About resumeMode={resumeMode} />

    {!resumeMode &&
    <>
    <Skill/>
    <Contact />
    <Project hideHeader={false}/>
    </>
    }
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
