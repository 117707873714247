import React from 'react';
// import '../style/skill.css';
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { faReact, faAppStore, faPython, faDigitalOcean, faAws, faGoogle, faDocker, faUbuntu, faJenkins, faGithub, faNodeJs, faHtml5, faAngular } from '@fortawesome/free-brands-svg-icons';
import FadeInSection from '../../FadeInSections'
import Chip from '@material-ui/core/Chip';
import uog from '../../assets/img/uog.png';
import sit from '../../assets/img/sit.png';

function ResumeEdu () {
  const schools = [
    {
      id: 2,
      icon: uog,
      school: 'Singapore Institute of Technology - University of Glasgow',
      cert: 'Bachelor of Science in Computing Science',
      period: '2019 - 2023',
    },
    {
      id: 1,
      icon: sit,
      school: 'Nanyang Polytechnic',
      cert: 'Diploma in Information Technology',
      period: '2014 - 2017',
    }
  ]
  return  (
    <div className="container" id="edu" style={{ paddingTop: '0.5em' }}>
        <div className="skills-container" style={{ maxWidth: "80% ", paddingBottom: '0.5em'}}>
          <h2>Education</h2>
          { schools.map(school => (
            <div style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "left",
              marginBottom: '1.5em',
            }}>
              <div>
                <img src={school.icon} style={{ width: '50px', height: '50px', borderRadius: '25px'}}/>
              </div>
              <div style={{
                marginLeft: "1em",
                marginTop: "0.2em"
              }}>
              <h5 style={{ marginTop: '0', marginBottom: '0', color:'#000' }}>{school.school}</h5>
              <h5 style={{ marginTop: '0', marginBottom: '0', color: '#5A5E65' }}>{school.cert}</h5>
              <h6 style={{ marginTop: '0', marginBottom: '0', color: '#9ca3af' }}>{school.period}</h6>
              </div>
              </div>
          ))}
        </div>
    </div>
  );
}

export default ResumeEdu;